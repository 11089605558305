import { useState, useEffect, useCallback } from 'react';
import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
  ISignUpResult,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { boolean } from 'boolean';
import _ from 'lodash';
import { useApolloClient } from '@apollo/client';

import CognitoConfig from '../config/CognitoConfig';
import { resetAuthLink } from "./AppSyncProvider";
import { SessionStore } from "./SessionStore";

const userPool = new CognitoUserPool(CognitoConfig);

export interface CurrentUser {
  setAt: number;
  session: CognitoUserSession;
  isSuperAdmin: boolean;
  name?: string;
  email?: string;
  verified?: boolean;
}

interface GetCurrentUserResult {
  session: CognitoUserSession;
  isSuperAdmin: boolean;
  name?: string;
  email?: string;
  verified?: boolean;
}

interface ErrorWithCode {
  code?: string;
  message: string;
}

export class WeakPasswordError extends Error {
  constructor() {
    super('Password should be at least 8 characters');
  }
}

export class UserExistsError extends Error {
  readonly email: string;

  constructor(email: string) {
    super('A user already exists with this email');
    this.email = email;
  }
}

export class InvalidEmailError extends Error {
  readonly email: string;

  constructor(email: string) {
    super('The email provided is not a valid address.');
    this.email = email;
  }
}

export class RequiredFieldError extends Error {
  readonly field: string;

  constructor(field: string) {
    super(`${_.capitalize(field)} is required for registration.`);
    this.field = field;
  }
}

export class UnauthorizedError extends Error {
  constructor() {
    super('Invalid email or password.');
  }
}

export class UnconfirmedEmailError extends Error {
  readonly email: string;

  constructor(email: string) {
    super(
      `Your e-mail needs to be verified. Please check your e-mail for a verification link.`
    );
    this.email = email;
  }
}

export class IncorrectCodeException extends Error {
  constructor() {
    super('Incorrect password reset code.');
  }
}

export class InvalidCodeException extends Error {
  constructor() {
    super('Code is expired or user does not exist.');
  }
}

export const createUser = async (
  email: string,
  password: string,
  name: string
) => {
  if (!(name || '').trim()) {
    throw new RequiredFieldError('name');
  }

  const attributeList = [
    new CognitoUserAttribute({
      Name: 'name',
      Value: name.trim(),
    }),
  ];

  const signupPromise = new Promise<ISignUpResult>((resolve, reject) => {
    userPool.signUp(
      email.trim(),
      password.trim(),
      attributeList,
      [],
      (err, result) => {
        err ? reject(err) : resolve(result!);
      }
    );
  });

  try {
    const signupResult = await signupPromise;
    return signupResult.user;
  } catch (err) {
    switch ((err as ErrorWithCode).code) {
      case 'UsernameExistsException':
        throw new UserExistsError(email);
      case 'InvalidPasswordException':
        throw new WeakPasswordError();
      default:
        if ((err as ErrorWithCode).message === 'Username should be an email.') {
          throw new InvalidEmailError(email);
        }
        throw err;
    }
  }
};

// export const verifyUser = (username, verifyCode, callback) => {
//   const userData = {
//     Username: username,
//     Pool: userPool,
//   };
//   const cognitoUser = new CognitoUser(userData);
//   cognitoUser.confirmRegistration(verifyCode, true, callback);
// };

export const authenticateUser = async (email: string, password: string) => {
  const credentials = new AuthenticationDetails({
    Username: email,
    Password: password,
  });
  const user = new CognitoUser({
    Username: email,
    Pool: userPool,
  });
  user.setAuthenticationFlowType('USER_PASSWORD_AUTH');

  const authenticationPromise = new Promise((onSuccess, onFailure) =>
    user.authenticateUser(credentials, { onSuccess, onFailure })
  );

  try {
    return await authenticationPromise;
  } catch (err) {
    switch ((err as ErrorWithCode).code) {
      case 'NotAuthorizedException':
        throw new UnauthorizedError();
      case 'UserNotConfirmedException':
        throw new UnconfirmedEmailError(email);
      default:
        throw err;
    }
  }
};

export async function requestUserPasswordResetCode(
  email: string
): Promise<any | void> {
  const user = new CognitoUser({
    Username: email,
    Pool: userPool,
  });

  return new Promise((onSuccess, onFailure) =>
    user.forgotPassword({ onSuccess, onFailure })
  );
}

export async function resetUserPassword(
  email: string,
  password: string,
  code: string
): Promise<any> {
  const user = new CognitoUser({
    Username: email,
    Pool: userPool,
  });

  return new Promise((resolve, reject) =>
    user.confirmPassword(code, password, {
      onSuccess: resolve,
      onFailure: (err) => {
        switch ((err as ErrorWithCode).code) {
          case 'CodeMismatchException':
            return reject(new IncorrectCodeException());
          case 'ExpiredCodeException':
            return reject(new InvalidCodeException());
          default:
            return reject(err);
        }
      },
    })
  );
}

export async function changeUserPassword(
  email: string,
  password: string,
  newPassword: string
): Promise<void> {
  const user = await userPool.getCurrentUser();
  await new Promise((resolve) => user?.getSession(resolve));
  if (!user || user.getUsername() !== email) {
    throw new UnauthorizedError();
  }

  const changePassword = new Promise<void>((resolve, reject) =>
    user.changePassword(password, newPassword, (err, result) =>
      err ? reject(err) : resolve()
    )
  );

  try {
    await changePassword;
  } catch (err) {
    switch ((err as ErrorWithCode).code) {
      case 'NotAuthorizedException':
        throw new UnauthorizedError();
      default:
        throw new UnauthorizedError();
    }
  }
}

export function resendConfirmationEmail(email: string): Promise<void> {
  const user = new CognitoUser({
    Username: email,
    Pool: userPool,
  });

  return new Promise((resolve, reject) =>
    user.resendConfirmationCode((err, result) =>
      err ? reject(err) : resolve(result)
    )
  );
}

export async function signOut(reload: boolean = false): Promise<void> {
  const user = userPool.getCurrentUser();
  if (user) {
    await new Promise((resolve) => user.signOut(() => resolve(undefined))).then(
      () => (reload ? window.location.reload() : undefined)
    );
  }
}

export async function getCurrentUserToken(): Promise<string> {
  const currentUser = await getCurrentUser();
  if (currentUser && 'session' in currentUser) {
    const session = currentUser.session as CognitoUserSession;
    const token = session.getIdToken().getJwtToken();
    return token;
  }
  throw new Error('No access token found');
}

export async function getCurrentUser(): Promise<GetCurrentUserResult | null> {
  const currentUser = userPool.getCurrentUser();
  if (!currentUser) return null;

  const session: CognitoUserSession | null = await new Promise((resolve) => {
    currentUser.getSession(
      (err?: Error, session?: CognitoUserSession | null) => {
        if (err) {
          console.log(err);
          resolve(null);
        }
        resolve(session!);
      }
    );
  });

  if (!session || !session.isValid()) return null;

  const userAttributes: { [name: string]: string } = await new Promise(
    (resolve, reject) => {
      currentUser.getUserAttributes((err, attributeResponse) => {
        if (err || !attributeResponse) {
          reject(err);
        }
        const attributes = _.merge(
          {},
          ...attributeResponse!.map(({ Name, Value }) => ({ [Name]: Value }))
        );
        attributes.verified = boolean(attributes.email_verified);
        delete attributes.email_verified;
        resolve(attributes);
      });
    }
  );

  const isSuperAdmin: boolean = _.get(
    session.getIdToken(),
    'payload.cognito:groups',
    []
  ).includes('Forms-Administrators');

  return {
    ...userAttributes,
    isSuperAdmin,
    session,
  };
}

export function useAuth() {
  const [trigger, setTrigger] = useState(0);
  const [user, setUser] = useState<CurrentUser | null>(null);
  const [userLoading, setUserLoading] = useState<boolean>(true);
  const apolloClient = useApolloClient();

  useEffect(() => {
    let memoizedUser;
    if (user && Date.now() - user.setAt < 1000) {
      memoizedUser = Promise.resolve(user);
      resetAuthLink()
    }

    (memoizedUser || getCurrentUser())
      .then((_user) => {
        if (!_.isEqual(_user, _.omit(user, 'setAt'))) {
          setUser(_user ? { setAt: Date.now(), ..._user } : null);
        }
      })
      .then(() => resetAuthLink())
      .then(() => setUserLoading(false));
  }, [trigger, user]);

  const refreshSession = useCallback(() => {
    setTrigger(Math.random());
  }, [setTrigger]);

  const _logout = useCallback(() => {
    setUserLoading(true);
    return signOut()
      .then(() => SessionStore.clearAuthedResponses())
      .then(() => setUser(null))
      .then(() => apolloClient.clearStore())
      .then(() => resetAuthLink())
    .then(() => setUserLoading(false));

  }, [apolloClient]);

  const authenticate = useCallback(
    async (email: string, password: string) => {
      await authenticateUser(email, password);
      const user = await getCurrentUser();
      setUser(user ? { setAt: Date.now(), ...user } : null);
    },
    [setUser]
  );

  return {
    user,
    setUser,
    userLoading,
    logout: _logout,
    authenticate,
    refreshSession,
  };
}
