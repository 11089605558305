import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: string;
  AWSDateTime: string;
  AWSEmail: string;
  AWSIPAddress: string;
  AWSJSON: string;
  AWSPhone: string;
  AWSTime: string;
  AWSTimestamp: number;
  AWSURL: string;
};

export type Mutation = {
  __typename?: 'Mutation';
  createResponse?: Maybe<Response>;
  createSurvey?: Maybe<Survey>;
  createSurveyVersion?: Maybe<SurveyVersion>;
  createUser?: Maybe<User>;
  exportAllToCSV?: Maybe<Array<Maybe<Scalars['String']>>>;
  publishSurveyVersion?: Maybe<SurveyVersion>;
  unpublishSurveyVersion?: Maybe<Scalars['Boolean']>;
  updateResponse?: Maybe<Response>;
  updateSelf?: Maybe<User>;
  updateSurvey?: Maybe<Survey>;
  updateSurveyVersion?: Maybe<SurveyVersion>;
};


export type MutationCreateResponseArgs = {
  input: ResponseInput;
};


export type MutationCreateSurveyArgs = {
  input: SurveyInput;
};


export type MutationCreateSurveyVersionArgs = {
  input: SurveyVersionInput;
};


export type MutationCreateUserArgs = {
  input?: InputMaybe<UserInput>;
};


export type MutationExportAllToCsvArgs = {
  filter: ResponseInput;
};


export type MutationPublishSurveyVersionArgs = {
  id: Scalars['ID'];
};


export type MutationUnpublishSurveyVersionArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateResponseArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<ResponseInput>;
};


export type MutationUpdateSelfArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<UserInput>;
};


export type MutationUpdateSurveyArgs = {
  id: Scalars['ID'];
  input: SurveyInput;
};


export type MutationUpdateSurveyVersionArgs = {
  id: Scalars['ID'];
  input: SurveyVersionInput;
};

export type Query = {
  __typename?: 'Query';
  getCurrentUser?: Maybe<User>;
  getResponse?: Maybe<Response>;
  getResponses?: Maybe<Array<Maybe<Response>>>;
  getResponsesMetadata?: Maybe<Array<Maybe<Response>>>;
  getSurvey?: Maybe<Survey>;
  getSurveyVersion?: Maybe<SurveyVersion>;
  getSurveyVersions?: Maybe<Array<Maybe<SurveyVersion>>>;
  getSurveys?: Maybe<Array<Maybe<Survey>>>;
};


export type QueryGetResponseArgs = {
  id: Scalars['ID'];
};


export type QueryGetResponsesArgs = {
  filter?: InputMaybe<ResponseInput>;
};


export type QueryGetResponsesMetadataArgs = {
  filter?: InputMaybe<ResponseInput>;
};


export type QueryGetSurveyArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetSurveyVersionArgs = {
  id: Scalars['ID'];
};


export type QueryGetSurveyVersionsArgs = {
  filter?: InputMaybe<SurveyVersionInput>;
};


export type QueryGetSurveysArgs = {
  filter?: InputMaybe<SurveyInput>;
};

export type Response = {
  __typename?: 'Response';
  client?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  data?: Maybe<Scalars['AWSJSON']>;
  id: Scalars['ID'];
  isCompleted?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<Scalars['String']>;
  survey?: Maybe<Survey>;
  surveyVersion?: Maybe<SurveyVersion>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  user?: Maybe<Scalars['AWSEmail']>;
};

export type ResponseInput = {
  client?: InputMaybe<Scalars['ID']>;
  data?: InputMaybe<Scalars['AWSJSON']>;
  id?: InputMaybe<Scalars['ID']>;
  isCompleted?: InputMaybe<Scalars['Boolean']>;
  survey?: InputMaybe<SurveyInput>;
  surveyVersion?: InputMaybe<SurveyVersionInput>;
  user?: InputMaybe<Scalars['AWSEmail']>;
};

export type Survey = {
  __typename?: 'Survey';
  active?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inactiveMessage?: Maybe<Scalars['String']>;
  publishedVersion?: Maybe<SurveyVersion>;
  savable?: Maybe<Scalars['Boolean']>;
  shortname?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
};

export type SurveyInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  publishedVersion?: InputMaybe<SurveyVersionInput>;
  savable?: InputMaybe<Scalars['Boolean']>;
  shortname?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type SurveyVersion = {
  __typename?: 'SurveyVersion';
  config?: Maybe<Scalars['AWSJSON']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  survey?: Maybe<Survey>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
};

export type SurveyVersionInput = {
  config?: InputMaybe<Scalars['AWSJSON']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  surveyId?: InputMaybe<Scalars['ID']>;
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type UserInput = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CreateSurveyMutationVariables = Exact<{
  input: SurveyInput;
}>;


export type CreateSurveyMutation = { __typename?: 'Mutation', createSurvey?: { __typename?: 'Survey', id: string, shortname?: string | null } | null };

export type GetSurveyVersionAdminQueryVariables = Exact<{
  surveyVersionId: Scalars['ID'];
}>;


export type GetSurveyVersionAdminQuery = { __typename?: 'Query', getSurveyVersion?: { __typename?: 'SurveyVersion', id: string, config?: string | null } | null };

export type GetSurveyQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;


export type GetSurveyQuery = { __typename?: 'Query', getSurvey?: { __typename?: 'Survey', id: string, title?: string | null, shortname?: string | null, description?: string | null } | null };

export type GetResponsesMetadataQueryVariables = Exact<{
  filter?: InputMaybe<ResponseInput>;
}>;


export type GetResponsesMetadataQuery = { __typename?: 'Query', getResponsesMetadata?: Array<{ __typename?: 'Response', id: string, user?: string | null, isCompleted?: boolean | null, organization?: string | null, updatedAt?: string | null, createdAt?: string | null, survey?: { __typename?: 'Survey', id: string, shortname?: string | null } | null, surveyVersion?: { __typename?: 'SurveyVersion', id: string, name?: string | null } | null } | null> | null };

export type GetResponseForDownloadQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetResponseForDownloadQuery = { __typename?: 'Query', getResponse?: { __typename?: 'Response', id: string, user?: string | null, isCompleted?: boolean | null, data?: string | null, updatedAt?: string | null, survey?: { __typename?: 'Survey', id: string } | null, surveyVersion?: { __typename?: 'SurveyVersion', id: string, config?: string | null } | null } | null };

export type ExportAllToCsvMutationVariables = Exact<{
  filter: ResponseInput;
}>;


export type ExportAllToCsvMutation = { __typename?: 'Mutation', exportAllToCSV?: Array<string | null> | null };

export type GetAllSurveysAdminQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllSurveysAdminQuery = { __typename?: 'Query', getSurveys?: Array<{ __typename?: 'Survey', id: string, savable?: boolean | null, active?: boolean | null, inactiveMessage?: string | null, archived?: boolean | null, shortname?: string | null, title?: string | null, description?: string | null, publishedVersion?: { __typename?: 'SurveyVersion', id: string, name?: string | null, config?: string | null } | null } | null> | null };

export type UpdateSurveyAdminMutationVariables = Exact<{
  surveyId: Scalars['ID'];
  input: SurveyInput;
}>;


export type UpdateSurveyAdminMutation = { __typename?: 'Mutation', updateSurvey?: { __typename?: 'Survey', id: string, savable?: boolean | null, active?: boolean | null, archived?: boolean | null, shortname?: string | null, title?: string | null, description?: string | null } | null };

export type GetAllSurveyVersionsAdminQueryVariables = Exact<{
  surveyId: Scalars['ID'];
}>;


export type GetAllSurveyVersionsAdminQuery = { __typename?: 'Query', getSurvey?: { __typename?: 'Survey', id: string, title?: string | null, publishedVersion?: { __typename?: 'SurveyVersion', id: string, name?: string | null } | null } | null, getSurveyVersions?: Array<{ __typename?: 'SurveyVersion', id: string, name?: string | null, config?: string | null, updatedAt?: string | null } | null> | null };

export type PublishSurveyVersionMutationVariables = Exact<{
  surveyVersionId: Scalars['ID'];
}>;


export type PublishSurveyVersionMutation = { __typename?: 'Mutation', publishSurveyVersion?: { __typename?: 'SurveyVersion', id: string, updatedAt?: string | null } | null };

export type UnpublishSurveyVersionMutationVariables = Exact<{
  surveyVersionId: Scalars['ID'];
}>;


export type UnpublishSurveyVersionMutation = { __typename?: 'Mutation', unpublishSurveyVersion?: boolean | null };

export type UpdateSurveyVersionMutationVariables = Exact<{
  surveyVersionId: Scalars['ID'];
  input: SurveyVersionInput;
}>;


export type UpdateSurveyVersionMutation = { __typename?: 'Mutation', updateSurveyVersion?: { __typename?: 'SurveyVersion', id: string, name?: string | null, config?: string | null } | null };

export type CreateSurveyVersionMutationVariables = Exact<{
  input: SurveyVersionInput;
}>;


export type CreateSurveyVersionMutation = { __typename?: 'Mutation', createSurveyVersion?: { __typename?: 'SurveyVersion', id: string, name?: string | null, config?: string | null } | null };

export type GetResponseWithConfigQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetResponseWithConfigQuery = { __typename?: 'Query', getResponse?: { __typename?: 'Response', id: string, user?: string | null, isCompleted?: boolean | null, data?: string | null, updatedAt?: string | null, survey?: { __typename?: 'Survey', id: string } | null, surveyVersion?: { __typename?: 'SurveyVersion', id: string, config?: string | null } | null } | null };

export type UpdateSelfMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<UserInput>;
}>;


export type UpdateSelfMutation = { __typename?: 'Mutation', updateSelf?: { __typename?: 'User', id?: number | null, name: string, email: string } | null };

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = { __typename?: 'Query', getCurrentUser?: { __typename?: 'User', id?: number | null, name: string, email: string } | null };

export type GetSurveysQueryVariables = Exact<{
  active?: InputMaybe<Scalars['Boolean']>;
  archived?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetSurveysQuery = { __typename?: 'Query', getSurveys?: Array<{ __typename?: 'Survey', id: string, active?: boolean | null, shortname?: string | null, title?: string | null, description?: string | null, publishedVersion?: { __typename?: 'SurveyVersion', id: string } | null } | null> | null };

export type GetSurveyDataByShortnameQueryVariables = Exact<{
  shortname?: InputMaybe<Scalars['String']>;
}>;


export type GetSurveyDataByShortnameQuery = { __typename?: 'Query', getSurveys?: Array<{ __typename?: 'Survey', id: string, shortname?: string | null, title?: string | null, description?: string | null, savable?: boolean | null, active?: boolean | null, inactiveMessage?: string | null, publishedVersion?: { __typename?: 'SurveyVersion', id: string, name?: string | null, config?: string | null } | null } | null> | null };

export type GetResponseQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetResponseQuery = { __typename?: 'Query', getResponse?: { __typename?: 'Response', id: string, user?: string | null, isCompleted?: boolean | null, data?: string | null, updatedAt?: string | null, survey?: { __typename?: 'Survey', id: string } | null, surveyVersion?: { __typename?: 'SurveyVersion', id: string } | null } | null };

export type CreateResponseMutationVariables = Exact<{
  input: ResponseInput;
}>;


export type CreateResponseMutation = { __typename?: 'Mutation', createResponse?: { __typename?: 'Response', id: string } | null };

export type UpdateResponseMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: InputMaybe<ResponseInput>;
}>;


export type UpdateResponseMutation = { __typename?: 'Mutation', updateResponse?: { __typename?: 'Response', id: string, user?: string | null, isCompleted?: boolean | null, data?: string | null, updatedAt?: string | null, survey?: { __typename?: 'Survey', id: string } | null, surveyVersion?: { __typename?: 'SurveyVersion', id: string } | null } | null };

export type GetMyResponsesQueryVariables = Exact<{
  filter?: InputMaybe<ResponseInput>;
}>;


export type GetMyResponsesQuery = { __typename?: 'Query', getResponses?: Array<{ __typename?: 'Response', id: string, user?: string | null, isCompleted?: boolean | null, data?: string | null, updatedAt?: string | null, createdAt?: string | null, survey?: { __typename?: 'Survey', id: string, title?: string | null, shortname?: string | null } | null, surveyVersion?: { __typename?: 'SurveyVersion', id: string, config?: string | null, name?: string | null } | null } | null> | null };


export const CreateSurveyDocument = gql`
    mutation createSurvey($input: SurveyInput!) {
  createSurvey(input: $input) {
    id
    shortname
  }
}
    `;
export type CreateSurveyMutationFn = Apollo.MutationFunction<CreateSurveyMutation, CreateSurveyMutationVariables>;

/**
 * __useCreateSurveyMutation__
 *
 * To run a mutation, you first call `useCreateSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSurveyMutation, { data, loading, error }] = useCreateSurveyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSurveyMutation(baseOptions?: Apollo.MutationHookOptions<CreateSurveyMutation, CreateSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSurveyMutation, CreateSurveyMutationVariables>(CreateSurveyDocument, options);
      }
export type CreateSurveyMutationHookResult = ReturnType<typeof useCreateSurveyMutation>;
export type CreateSurveyMutationResult = Apollo.MutationResult<CreateSurveyMutation>;
export type CreateSurveyMutationOptions = Apollo.BaseMutationOptions<CreateSurveyMutation, CreateSurveyMutationVariables>;
export const GetSurveyVersionAdminDocument = gql`
    query getSurveyVersionAdmin($surveyVersionId: ID!) {
  getSurveyVersion(id: $surveyVersionId) {
    id
    config
  }
}
    `;

/**
 * __useGetSurveyVersionAdminQuery__
 *
 * To run a query within a React component, call `useGetSurveyVersionAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveyVersionAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyVersionAdminQuery({
 *   variables: {
 *      surveyVersionId: // value for 'surveyVersionId'
 *   },
 * });
 */
export function useGetSurveyVersionAdminQuery(baseOptions: Apollo.QueryHookOptions<GetSurveyVersionAdminQuery, GetSurveyVersionAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSurveyVersionAdminQuery, GetSurveyVersionAdminQueryVariables>(GetSurveyVersionAdminDocument, options);
      }
export function useGetSurveyVersionAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSurveyVersionAdminQuery, GetSurveyVersionAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSurveyVersionAdminQuery, GetSurveyVersionAdminQueryVariables>(GetSurveyVersionAdminDocument, options);
        }
export type GetSurveyVersionAdminQueryHookResult = ReturnType<typeof useGetSurveyVersionAdminQuery>;
export type GetSurveyVersionAdminLazyQueryHookResult = ReturnType<typeof useGetSurveyVersionAdminLazyQuery>;
export type GetSurveyVersionAdminQueryResult = Apollo.QueryResult<GetSurveyVersionAdminQuery, GetSurveyVersionAdminQueryVariables>;
export const GetSurveyDocument = gql`
    query getSurvey($id: ID) {
  getSurvey(id: $id) {
    id
    title
    shortname
    description
  }
}
    `;

/**
 * __useGetSurveyQuery__
 *
 * To run a query within a React component, call `useGetSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSurveyQuery(baseOptions?: Apollo.QueryHookOptions<GetSurveyQuery, GetSurveyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSurveyQuery, GetSurveyQueryVariables>(GetSurveyDocument, options);
      }
export function useGetSurveyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSurveyQuery, GetSurveyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSurveyQuery, GetSurveyQueryVariables>(GetSurveyDocument, options);
        }
export type GetSurveyQueryHookResult = ReturnType<typeof useGetSurveyQuery>;
export type GetSurveyLazyQueryHookResult = ReturnType<typeof useGetSurveyLazyQuery>;
export type GetSurveyQueryResult = Apollo.QueryResult<GetSurveyQuery, GetSurveyQueryVariables>;
export const GetResponsesMetadataDocument = gql`
    query getResponsesMetadata($filter: ResponseInput) {
  getResponsesMetadata(filter: $filter) {
    id
    user
    isCompleted
    organization
    survey {
      id
      shortname
    }
    surveyVersion {
      id
      name
    }
    updatedAt
    createdAt
  }
}
    `;

/**
 * __useGetResponsesMetadataQuery__
 *
 * To run a query within a React component, call `useGetResponsesMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResponsesMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResponsesMetadataQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetResponsesMetadataQuery(baseOptions?: Apollo.QueryHookOptions<GetResponsesMetadataQuery, GetResponsesMetadataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResponsesMetadataQuery, GetResponsesMetadataQueryVariables>(GetResponsesMetadataDocument, options);
      }
export function useGetResponsesMetadataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResponsesMetadataQuery, GetResponsesMetadataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResponsesMetadataQuery, GetResponsesMetadataQueryVariables>(GetResponsesMetadataDocument, options);
        }
export type GetResponsesMetadataQueryHookResult = ReturnType<typeof useGetResponsesMetadataQuery>;
export type GetResponsesMetadataLazyQueryHookResult = ReturnType<typeof useGetResponsesMetadataLazyQuery>;
export type GetResponsesMetadataQueryResult = Apollo.QueryResult<GetResponsesMetadataQuery, GetResponsesMetadataQueryVariables>;
export const GetResponseForDownloadDocument = gql`
    query getResponseForDownload($id: ID!) {
  getResponse(id: $id) {
    id
    user
    isCompleted
    survey {
      id
    }
    surveyVersion {
      id
      config
    }
    data
    updatedAt
  }
}
    `;

/**
 * __useGetResponseForDownloadQuery__
 *
 * To run a query within a React component, call `useGetResponseForDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResponseForDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResponseForDownloadQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetResponseForDownloadQuery(baseOptions: Apollo.QueryHookOptions<GetResponseForDownloadQuery, GetResponseForDownloadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResponseForDownloadQuery, GetResponseForDownloadQueryVariables>(GetResponseForDownloadDocument, options);
      }
export function useGetResponseForDownloadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResponseForDownloadQuery, GetResponseForDownloadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResponseForDownloadQuery, GetResponseForDownloadQueryVariables>(GetResponseForDownloadDocument, options);
        }
export type GetResponseForDownloadQueryHookResult = ReturnType<typeof useGetResponseForDownloadQuery>;
export type GetResponseForDownloadLazyQueryHookResult = ReturnType<typeof useGetResponseForDownloadLazyQuery>;
export type GetResponseForDownloadQueryResult = Apollo.QueryResult<GetResponseForDownloadQuery, GetResponseForDownloadQueryVariables>;
export const ExportAllToCsvDocument = gql`
    mutation exportAllToCSV($filter: ResponseInput!) {
  exportAllToCSV(filter: $filter)
}
    `;
export type ExportAllToCsvMutationFn = Apollo.MutationFunction<ExportAllToCsvMutation, ExportAllToCsvMutationVariables>;

/**
 * __useExportAllToCsvMutation__
 *
 * To run a mutation, you first call `useExportAllToCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportAllToCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportAllToCsvMutation, { data, loading, error }] = useExportAllToCsvMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportAllToCsvMutation(baseOptions?: Apollo.MutationHookOptions<ExportAllToCsvMutation, ExportAllToCsvMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportAllToCsvMutation, ExportAllToCsvMutationVariables>(ExportAllToCsvDocument, options);
      }
export type ExportAllToCsvMutationHookResult = ReturnType<typeof useExportAllToCsvMutation>;
export type ExportAllToCsvMutationResult = Apollo.MutationResult<ExportAllToCsvMutation>;
export type ExportAllToCsvMutationOptions = Apollo.BaseMutationOptions<ExportAllToCsvMutation, ExportAllToCsvMutationVariables>;
export const GetAllSurveysAdminDocument = gql`
    query getAllSurveysAdmin {
  getSurveys {
    id
    savable
    active
    inactiveMessage
    archived
    shortname
    title
    description
    publishedVersion {
      id
      name
      config
    }
  }
}
    `;

/**
 * __useGetAllSurveysAdminQuery__
 *
 * To run a query within a React component, call `useGetAllSurveysAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSurveysAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSurveysAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllSurveysAdminQuery(baseOptions?: Apollo.QueryHookOptions<GetAllSurveysAdminQuery, GetAllSurveysAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllSurveysAdminQuery, GetAllSurveysAdminQueryVariables>(GetAllSurveysAdminDocument, options);
      }
export function useGetAllSurveysAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSurveysAdminQuery, GetAllSurveysAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllSurveysAdminQuery, GetAllSurveysAdminQueryVariables>(GetAllSurveysAdminDocument, options);
        }
export type GetAllSurveysAdminQueryHookResult = ReturnType<typeof useGetAllSurveysAdminQuery>;
export type GetAllSurveysAdminLazyQueryHookResult = ReturnType<typeof useGetAllSurveysAdminLazyQuery>;
export type GetAllSurveysAdminQueryResult = Apollo.QueryResult<GetAllSurveysAdminQuery, GetAllSurveysAdminQueryVariables>;
export const UpdateSurveyAdminDocument = gql`
    mutation updateSurveyAdmin($surveyId: ID!, $input: SurveyInput!) {
  updateSurvey(id: $surveyId, input: $input) {
    id
    savable
    active
    archived
    shortname
    title
    description
  }
}
    `;
export type UpdateSurveyAdminMutationFn = Apollo.MutationFunction<UpdateSurveyAdminMutation, UpdateSurveyAdminMutationVariables>;

/**
 * __useUpdateSurveyAdminMutation__
 *
 * To run a mutation, you first call `useUpdateSurveyAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSurveyAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSurveyAdminMutation, { data, loading, error }] = useUpdateSurveyAdminMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSurveyAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSurveyAdminMutation, UpdateSurveyAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSurveyAdminMutation, UpdateSurveyAdminMutationVariables>(UpdateSurveyAdminDocument, options);
      }
export type UpdateSurveyAdminMutationHookResult = ReturnType<typeof useUpdateSurveyAdminMutation>;
export type UpdateSurveyAdminMutationResult = Apollo.MutationResult<UpdateSurveyAdminMutation>;
export type UpdateSurveyAdminMutationOptions = Apollo.BaseMutationOptions<UpdateSurveyAdminMutation, UpdateSurveyAdminMutationVariables>;
export const GetAllSurveyVersionsAdminDocument = gql`
    query getAllSurveyVersionsAdmin($surveyId: ID!) {
  getSurvey(id: $surveyId) {
    id
    title
    publishedVersion {
      id
      name
    }
  }
  getSurveyVersions(filter: {surveyId: $surveyId}) {
    id
    name
    config
    updatedAt
  }
}
    `;

/**
 * __useGetAllSurveyVersionsAdminQuery__
 *
 * To run a query within a React component, call `useGetAllSurveyVersionsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSurveyVersionsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSurveyVersionsAdminQuery({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useGetAllSurveyVersionsAdminQuery(baseOptions: Apollo.QueryHookOptions<GetAllSurveyVersionsAdminQuery, GetAllSurveyVersionsAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllSurveyVersionsAdminQuery, GetAllSurveyVersionsAdminQueryVariables>(GetAllSurveyVersionsAdminDocument, options);
      }
export function useGetAllSurveyVersionsAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSurveyVersionsAdminQuery, GetAllSurveyVersionsAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllSurveyVersionsAdminQuery, GetAllSurveyVersionsAdminQueryVariables>(GetAllSurveyVersionsAdminDocument, options);
        }
export type GetAllSurveyVersionsAdminQueryHookResult = ReturnType<typeof useGetAllSurveyVersionsAdminQuery>;
export type GetAllSurveyVersionsAdminLazyQueryHookResult = ReturnType<typeof useGetAllSurveyVersionsAdminLazyQuery>;
export type GetAllSurveyVersionsAdminQueryResult = Apollo.QueryResult<GetAllSurveyVersionsAdminQuery, GetAllSurveyVersionsAdminQueryVariables>;
export const PublishSurveyVersionDocument = gql`
    mutation publishSurveyVersion($surveyVersionId: ID!) {
  publishSurveyVersion(id: $surveyVersionId) {
    id
    updatedAt
  }
}
    `;
export type PublishSurveyVersionMutationFn = Apollo.MutationFunction<PublishSurveyVersionMutation, PublishSurveyVersionMutationVariables>;

/**
 * __usePublishSurveyVersionMutation__
 *
 * To run a mutation, you first call `usePublishSurveyVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishSurveyVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishSurveyVersionMutation, { data, loading, error }] = usePublishSurveyVersionMutation({
 *   variables: {
 *      surveyVersionId: // value for 'surveyVersionId'
 *   },
 * });
 */
export function usePublishSurveyVersionMutation(baseOptions?: Apollo.MutationHookOptions<PublishSurveyVersionMutation, PublishSurveyVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishSurveyVersionMutation, PublishSurveyVersionMutationVariables>(PublishSurveyVersionDocument, options);
      }
export type PublishSurveyVersionMutationHookResult = ReturnType<typeof usePublishSurveyVersionMutation>;
export type PublishSurveyVersionMutationResult = Apollo.MutationResult<PublishSurveyVersionMutation>;
export type PublishSurveyVersionMutationOptions = Apollo.BaseMutationOptions<PublishSurveyVersionMutation, PublishSurveyVersionMutationVariables>;
export const UnpublishSurveyVersionDocument = gql`
    mutation UnpublishSurveyVersion($surveyVersionId: ID!) {
  unpublishSurveyVersion(id: $surveyVersionId)
}
    `;
export type UnpublishSurveyVersionMutationFn = Apollo.MutationFunction<UnpublishSurveyVersionMutation, UnpublishSurveyVersionMutationVariables>;

/**
 * __useUnpublishSurveyVersionMutation__
 *
 * To run a mutation, you first call `useUnpublishSurveyVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishSurveyVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishSurveyVersionMutation, { data, loading, error }] = useUnpublishSurveyVersionMutation({
 *   variables: {
 *      surveyVersionId: // value for 'surveyVersionId'
 *   },
 * });
 */
export function useUnpublishSurveyVersionMutation(baseOptions?: Apollo.MutationHookOptions<UnpublishSurveyVersionMutation, UnpublishSurveyVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnpublishSurveyVersionMutation, UnpublishSurveyVersionMutationVariables>(UnpublishSurveyVersionDocument, options);
      }
export type UnpublishSurveyVersionMutationHookResult = ReturnType<typeof useUnpublishSurveyVersionMutation>;
export type UnpublishSurveyVersionMutationResult = Apollo.MutationResult<UnpublishSurveyVersionMutation>;
export type UnpublishSurveyVersionMutationOptions = Apollo.BaseMutationOptions<UnpublishSurveyVersionMutation, UnpublishSurveyVersionMutationVariables>;
export const UpdateSurveyVersionDocument = gql`
    mutation updateSurveyVersion($surveyVersionId: ID!, $input: SurveyVersionInput!) {
  updateSurveyVersion(id: $surveyVersionId, input: $input) {
    id
    name
    config
  }
}
    `;
export type UpdateSurveyVersionMutationFn = Apollo.MutationFunction<UpdateSurveyVersionMutation, UpdateSurveyVersionMutationVariables>;

/**
 * __useUpdateSurveyVersionMutation__
 *
 * To run a mutation, you first call `useUpdateSurveyVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSurveyVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSurveyVersionMutation, { data, loading, error }] = useUpdateSurveyVersionMutation({
 *   variables: {
 *      surveyVersionId: // value for 'surveyVersionId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSurveyVersionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSurveyVersionMutation, UpdateSurveyVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSurveyVersionMutation, UpdateSurveyVersionMutationVariables>(UpdateSurveyVersionDocument, options);
      }
export type UpdateSurveyVersionMutationHookResult = ReturnType<typeof useUpdateSurveyVersionMutation>;
export type UpdateSurveyVersionMutationResult = Apollo.MutationResult<UpdateSurveyVersionMutation>;
export type UpdateSurveyVersionMutationOptions = Apollo.BaseMutationOptions<UpdateSurveyVersionMutation, UpdateSurveyVersionMutationVariables>;
export const CreateSurveyVersionDocument = gql`
    mutation createSurveyVersion($input: SurveyVersionInput!) {
  createSurveyVersion(input: $input) {
    id
    name
    config
  }
}
    `;
export type CreateSurveyVersionMutationFn = Apollo.MutationFunction<CreateSurveyVersionMutation, CreateSurveyVersionMutationVariables>;

/**
 * __useCreateSurveyVersionMutation__
 *
 * To run a mutation, you first call `useCreateSurveyVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSurveyVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSurveyVersionMutation, { data, loading, error }] = useCreateSurveyVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSurveyVersionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSurveyVersionMutation, CreateSurveyVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSurveyVersionMutation, CreateSurveyVersionMutationVariables>(CreateSurveyVersionDocument, options);
      }
export type CreateSurveyVersionMutationHookResult = ReturnType<typeof useCreateSurveyVersionMutation>;
export type CreateSurveyVersionMutationResult = Apollo.MutationResult<CreateSurveyVersionMutation>;
export type CreateSurveyVersionMutationOptions = Apollo.BaseMutationOptions<CreateSurveyVersionMutation, CreateSurveyVersionMutationVariables>;
export const GetResponseWithConfigDocument = gql`
    query getResponseWithConfig($id: ID!) {
  getResponse(id: $id) {
    id
    user
    isCompleted
    survey {
      id
    }
    surveyVersion {
      id
      config
    }
    data
    updatedAt
  }
}
    `;

/**
 * __useGetResponseWithConfigQuery__
 *
 * To run a query within a React component, call `useGetResponseWithConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResponseWithConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResponseWithConfigQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetResponseWithConfigQuery(baseOptions: Apollo.QueryHookOptions<GetResponseWithConfigQuery, GetResponseWithConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResponseWithConfigQuery, GetResponseWithConfigQueryVariables>(GetResponseWithConfigDocument, options);
      }
export function useGetResponseWithConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResponseWithConfigQuery, GetResponseWithConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResponseWithConfigQuery, GetResponseWithConfigQueryVariables>(GetResponseWithConfigDocument, options);
        }
export type GetResponseWithConfigQueryHookResult = ReturnType<typeof useGetResponseWithConfigQuery>;
export type GetResponseWithConfigLazyQueryHookResult = ReturnType<typeof useGetResponseWithConfigLazyQuery>;
export type GetResponseWithConfigQueryResult = Apollo.QueryResult<GetResponseWithConfigQuery, GetResponseWithConfigQueryVariables>;
export const UpdateSelfDocument = gql`
    mutation updateSelf($id: ID, $input: UserInput) {
  updateSelf(id: $id, input: $input) {
    id
    name
    email
  }
}
    `;
export type UpdateSelfMutationFn = Apollo.MutationFunction<UpdateSelfMutation, UpdateSelfMutationVariables>;

/**
 * __useUpdateSelfMutation__
 *
 * To run a mutation, you first call `useUpdateSelfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSelfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSelfMutation, { data, loading, error }] = useUpdateSelfMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSelfMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSelfMutation, UpdateSelfMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSelfMutation, UpdateSelfMutationVariables>(UpdateSelfDocument, options);
      }
export type UpdateSelfMutationHookResult = ReturnType<typeof useUpdateSelfMutation>;
export type UpdateSelfMutationResult = Apollo.MutationResult<UpdateSelfMutation>;
export type UpdateSelfMutationOptions = Apollo.BaseMutationOptions<UpdateSelfMutation, UpdateSelfMutationVariables>;
export const GetCurrentUserDocument = gql`
    query getCurrentUser {
  getCurrentUser {
    id
    name
    email
  }
}
    `;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const GetSurveysDocument = gql`
    query getSurveys($active: Boolean, $archived: Boolean) {
  getSurveys(filter: {active: $active, archived: $archived}) {
    id
    active
    shortname
    title
    description
    publishedVersion {
      id
    }
  }
}
    `;

/**
 * __useGetSurveysQuery__
 *
 * To run a query within a React component, call `useGetSurveysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveysQuery({
 *   variables: {
 *      active: // value for 'active'
 *      archived: // value for 'archived'
 *   },
 * });
 */
export function useGetSurveysQuery(baseOptions?: Apollo.QueryHookOptions<GetSurveysQuery, GetSurveysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSurveysQuery, GetSurveysQueryVariables>(GetSurveysDocument, options);
      }
export function useGetSurveysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSurveysQuery, GetSurveysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSurveysQuery, GetSurveysQueryVariables>(GetSurveysDocument, options);
        }
export type GetSurveysQueryHookResult = ReturnType<typeof useGetSurveysQuery>;
export type GetSurveysLazyQueryHookResult = ReturnType<typeof useGetSurveysLazyQuery>;
export type GetSurveysQueryResult = Apollo.QueryResult<GetSurveysQuery, GetSurveysQueryVariables>;
export const GetSurveyDataByShortnameDocument = gql`
    query getSurveyDataByShortname($shortname: String) {
  getSurveys(filter: {shortname: $shortname}) {
    id
    shortname
    title
    description
    savable
    active
    inactiveMessage
    publishedVersion {
      id
      name
      config
    }
  }
}
    `;

/**
 * __useGetSurveyDataByShortnameQuery__
 *
 * To run a query within a React component, call `useGetSurveyDataByShortnameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveyDataByShortnameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyDataByShortnameQuery({
 *   variables: {
 *      shortname: // value for 'shortname'
 *   },
 * });
 */
export function useGetSurveyDataByShortnameQuery(baseOptions?: Apollo.QueryHookOptions<GetSurveyDataByShortnameQuery, GetSurveyDataByShortnameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSurveyDataByShortnameQuery, GetSurveyDataByShortnameQueryVariables>(GetSurveyDataByShortnameDocument, options);
      }
export function useGetSurveyDataByShortnameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSurveyDataByShortnameQuery, GetSurveyDataByShortnameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSurveyDataByShortnameQuery, GetSurveyDataByShortnameQueryVariables>(GetSurveyDataByShortnameDocument, options);
        }
export type GetSurveyDataByShortnameQueryHookResult = ReturnType<typeof useGetSurveyDataByShortnameQuery>;
export type GetSurveyDataByShortnameLazyQueryHookResult = ReturnType<typeof useGetSurveyDataByShortnameLazyQuery>;
export type GetSurveyDataByShortnameQueryResult = Apollo.QueryResult<GetSurveyDataByShortnameQuery, GetSurveyDataByShortnameQueryVariables>;
export const GetResponseDocument = gql`
    query getResponse($id: ID!) {
  getResponse(id: $id) {
    id
    user
    isCompleted
    survey {
      id
    }
    surveyVersion {
      id
    }
    data
    updatedAt
  }
}
    `;

/**
 * __useGetResponseQuery__
 *
 * To run a query within a React component, call `useGetResponseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResponseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResponseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetResponseQuery(baseOptions: Apollo.QueryHookOptions<GetResponseQuery, GetResponseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResponseQuery, GetResponseQueryVariables>(GetResponseDocument, options);
      }
export function useGetResponseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResponseQuery, GetResponseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResponseQuery, GetResponseQueryVariables>(GetResponseDocument, options);
        }
export type GetResponseQueryHookResult = ReturnType<typeof useGetResponseQuery>;
export type GetResponseLazyQueryHookResult = ReturnType<typeof useGetResponseLazyQuery>;
export type GetResponseQueryResult = Apollo.QueryResult<GetResponseQuery, GetResponseQueryVariables>;
export const CreateResponseDocument = gql`
    mutation createResponse($input: ResponseInput!) {
  createResponse(input: $input) {
    id
  }
}
    `;
export type CreateResponseMutationFn = Apollo.MutationFunction<CreateResponseMutation, CreateResponseMutationVariables>;

/**
 * __useCreateResponseMutation__
 *
 * To run a mutation, you first call `useCreateResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createResponseMutation, { data, loading, error }] = useCreateResponseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateResponseMutation(baseOptions?: Apollo.MutationHookOptions<CreateResponseMutation, CreateResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateResponseMutation, CreateResponseMutationVariables>(CreateResponseDocument, options);
      }
export type CreateResponseMutationHookResult = ReturnType<typeof useCreateResponseMutation>;
export type CreateResponseMutationResult = Apollo.MutationResult<CreateResponseMutation>;
export type CreateResponseMutationOptions = Apollo.BaseMutationOptions<CreateResponseMutation, CreateResponseMutationVariables>;
export const UpdateResponseDocument = gql`
    mutation updateResponse($id: ID!, $input: ResponseInput) {
  updateResponse(id: $id, input: $input) {
    id
    user
    isCompleted
    survey {
      id
    }
    surveyVersion {
      id
    }
    data
    updatedAt
  }
}
    `;
export type UpdateResponseMutationFn = Apollo.MutationFunction<UpdateResponseMutation, UpdateResponseMutationVariables>;

/**
 * __useUpdateResponseMutation__
 *
 * To run a mutation, you first call `useUpdateResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateResponseMutation, { data, loading, error }] = useUpdateResponseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateResponseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateResponseMutation, UpdateResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateResponseMutation, UpdateResponseMutationVariables>(UpdateResponseDocument, options);
      }
export type UpdateResponseMutationHookResult = ReturnType<typeof useUpdateResponseMutation>;
export type UpdateResponseMutationResult = Apollo.MutationResult<UpdateResponseMutation>;
export type UpdateResponseMutationOptions = Apollo.BaseMutationOptions<UpdateResponseMutation, UpdateResponseMutationVariables>;
export const GetMyResponsesDocument = gql`
    query getMyResponses($filter: ResponseInput) {
  getResponses(filter: $filter) {
    id
    user
    isCompleted
    survey {
      id
      title
      shortname
    }
    surveyVersion {
      id
      config
      name
    }
    data
    updatedAt
    createdAt
  }
}
    `;

/**
 * __useGetMyResponsesQuery__
 *
 * To run a query within a React component, call `useGetMyResponsesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyResponsesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyResponsesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetMyResponsesQuery(baseOptions?: Apollo.QueryHookOptions<GetMyResponsesQuery, GetMyResponsesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyResponsesQuery, GetMyResponsesQueryVariables>(GetMyResponsesDocument, options);
      }
export function useGetMyResponsesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyResponsesQuery, GetMyResponsesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyResponsesQuery, GetMyResponsesQueryVariables>(GetMyResponsesDocument, options);
        }
export type GetMyResponsesQueryHookResult = ReturnType<typeof useGetMyResponsesQuery>;
export type GetMyResponsesLazyQueryHookResult = ReturnType<typeof useGetMyResponsesLazyQuery>;
export type GetMyResponsesQueryResult = Apollo.QueryResult<GetMyResponsesQuery, GetMyResponsesQueryVariables>;