import * as React from 'react';
import { useEffect } from 'react';
import {
  Box, Button,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { resendConfirmationEmail } from '../../../lib/Cognito';
import './styles.css';

interface ResendConfirmationCodeProps {
  email: string;
  isSignUp: boolean;
  login: () => Promise<void>;
  close: () => void;
}

const ResendConfirmationCode: React.FC<ResendConfirmationCodeProps> = ({
                                                                         email,
                                                                         isSignUp,
                                                                         login,
                                                                         close,
                                                                       }) => {
  useEffect(() => {
    const interval = setInterval(login || (() => {
    }), 3000);

    return () => clearInterval(interval);
  }, [login]);

  const userPrompt = isSignUp
    ? 'An email has been sent to you to activate your account. '
    : 'Your e-mail needs to be verified. ';

  return (
    <div>
      <IconButton
        color="primary"
        aria-label="close dialog"
        component="div"
        className="dialog-close-button"
        onClick={close}
      >
        <CloseIcon/>
      </IconButton>
      <Box sx={{ p: '4em', m: '2em' }}>
        <Stack spacing={3}>
          <Typography variant="h5">
            Activate your Perennia Online account
            <LinearProgress sx={{ height: '1px' }}/>
          </Typography>
          <Typography>
            {userPrompt}
            Please check your email for a verification link from Perennia.
            After confirming your email, you will automatically be
            logged in.
          </Typography>
          <div>
            <Typography>
              Didn't receive one?{' '}
            </Typography>
            <Button onClick={() => resendConfirmationEmail(email)} variant="outlined">
              Click to resend verification e-mail.
            </Button>
          </div>
        </Stack>
      </Box>
    </div>
  );
};

export default ResendConfirmationCode;
